<template>
  <div class="edit-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <vxe-button @click="addTable">新增</vxe-button>
        <vxe-button @click="deleteTable">清空</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="red">注意：不能添加商品单价为0的数据</div>
    <vxe-table
      ref="xTable"
      resizable
      show-overflow
      :data="productList"
      :edit-config="{
        trigger: 'click',
        mode: 'cell',
      }"
      height="100px"
    >
    <!-- <vxe-table show-overflow="" ref="fineTable" :data="productList" height="100px"> -->
      <vxe-table-column min-width="100" field="detailLineCode" title="行号"></vxe-table-column>
      <vxe-table-column min-width="100" field="productCode" title="品鉴酒编码"></vxe-table-column>
      <vxe-table-column min-width="100" field="productName" title="品鉴酒名称"></vxe-table-column>
      <!-- <vxe-table-column min-width="100" field="num" title="数量（瓶）"></vxe-table-column> -->
      <vxe-table-column  min-width="100" field="num" title="数量（瓶）">
         <template #default="{ row ,rowIndex}">
            <el-input-number
              v-model="row.num"
              :controls="false"
              :min="0"
              :precision="0"
              v-if="!disabled"
              @change="changeNum(row.num,rowIndex)"
            ></el-input-number>
             <!-- :max="row.remainingNum" 配额池数量-->
            <span v-else>{{ row.num }}</span>
          </template>
      </vxe-table-column>
      <vxe-table-column min-width="100" field="specification" title="规格"></vxe-table-column>
      <vxe-table-column min-width="100" field="price" title="单价"></vxe-table-column>
      <vxe-table-column min-width="100" field="money" title="金额"></vxe-table-column>
      <vxe-column title="操作" width="80"  v-if="!disabled">
        <template #default="{ row }">
          <template >
            <span @click="deletRow(row)" style="color:red; cursor: pointer;">删除</span>
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '../../../../../../utils/request';

import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'BudgetSubject',
  components: {
    SelectConfig,
  },
  props: {
    value: Object,
    disabled: Boolean,
    dealerCode: String,
    companyCode: String,
  },
  watch: {
    value() {
      this.productList = this.value.data;
    },
    dealerCode() {},
    companyCode() {},
  },
  data() {
    return {
      productList: [], // 当前商品信息
    };
  },
  mounted() {

  },
  methods: {
    changeNum(val, rowIndex) {
      let { price } = this.productList[rowIndex];
      price = price.toString().replace(/,/g, '');
      this.productList[rowIndex].money = this.toThousands((Number(price) * Number(val)).toFixed(2));
      this.$emit('change', this.productList);
    },
    // 删除行
    deletRow(row) {
      console.log(this.productList);
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$refs.xTable.remove(row);
        this.productList.forEach((item, index) => {
          if (item.id === row.id) {
            this.productList.splice(index, 1);
          }
        });
        this.$emit('change', this.productList);
        this.$emit('deleteAssociate', row); // 删除活动明细中的关联信息
        this.$message({ type: 'success', message: '删除成功!' });
      });
    },
    // 清空列表
    deleteTable() {
      this.$refs.xTable.remove();
      this.value.data = [];
      this.productList = [];
      this.$emit('clear');
    },

    // 新增预算信息
    addTable() {
      const { dealerCode, companyCode } = this;
      // if (!companyCode) {
      //   this.$message({
      //     type: 'error',
      //     message: '请先选择经销商!',
      //   });
      // } else if (!dealerCode) {
      //   this.$message({
      //     type: 'error',
      //     message: '请先选择公司主体!',
      //   });
      // } else {
      const params = {
        functionCode: 'tpm_product_list',
        data: this.productList,
        idKey: 'code',
        paramData: {
          // dealerCode,
          // companyCode,
        },
      };

      this.$refs.selectConfig.openSelectModal(params);
      // }
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    onGetSelect(val) {
      let data = this.getReset(val, [], 'tastingPoolId');
      data = data.filter((m) => m.price !== 0);
      data = data.map((v, index) => {
        const obj = v;
        obj.productCode = obj.productCodeGift;
        obj.productName = obj.productNameGift;
        obj.poolCode = obj.tastingPoolId;
        obj.detailLineCode = index + 1;
        obj.price = this.toThousands(obj.price.toFixed(2));
        return obj;
      });
      this.value.data = data;
      this.productList = data;
      this.$emit('change', data);
    },
    // 数字千位符格式化
    toThousands(num) {
      const str = num.toString().replace(/,/g, '');
      const integer = str.split('.')[0]; // 获取整数位
      const decimal = str.split('.')[1]; // 获取小数
      let res = [];
      if (integer.length > 3) {
        let counter = 0;
        for (let i = integer.length - 1; i >= 0; i -= 1) {
          counter += 1;
          res.unshift(integer[i]);
          if (!(counter % 3) && i !== 0) {
            res.unshift(',');
          }
        }
        if (decimal) {
          res = `${res.join('')}.${decimal}`;
        } else {
          res = res.join('');
        }
      } else {
        res = str;
      }
      return res;
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;

}
.red{
  color: #F66046;
}
</style>
