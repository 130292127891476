var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("vxe-button", { on: { click: _vm.addTable } }, [
                        _vm._v("新增"),
                      ]),
                      _c("vxe-button", { on: { click: _vm.deleteTable } }, [
                        _vm._v("清空"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              380174805
            ),
          })
        : _vm._e(),
      _c("div", { staticClass: "red" }, [
        _vm._v("注意：不能添加商品单价为0的数据"),
      ]),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            resizable: "",
            "show-overflow": "",
            data: _vm.productList,
            "edit-config": {
              trigger: "click",
              mode: "cell",
            },
            height: "100px",
          },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "detailLineCode",
              title: "行号",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productCode",
              title: "品鉴酒编码",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "productName",
              title: "品鉴酒名称",
            },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "num", title: "数量（瓶）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    !_vm.disabled
                      ? _c("el-input-number", {
                          attrs: { controls: false, min: 0, precision: 0 },
                          on: {
                            change: function ($event) {
                              return _vm.changeNum(row.num, rowIndex)
                            },
                          },
                          model: {
                            value: row.num,
                            callback: function ($$v) {
                              _vm.$set(row, "num", $$v)
                            },
                            expression: "row.num",
                          },
                        })
                      : _c("span", [_vm._v(_vm._s(row.num))]),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "specification",
              title: "规格",
            },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "price", title: "单价" },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "money", title: "金额" },
          }),
          !_vm.disabled
            ? _c("vxe-column", {
                attrs: { title: "操作", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deletRow(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  239364282
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }