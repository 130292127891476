import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import CompanyList from '../components/company_list.vue';
import DealerList from '../components/dealer_list.vue';
import ProductListTable from '../components/product_list_table.vue';

formCreate.component('CompanyList', CompanyList);
formCreate.component('DealerList', DealerList);
formCreate.component('ProductListTable', ProductListTable);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_tasting_wine_order_form',
      formParentCode: 'CRM20230728000000002',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },
  methods: {

    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'dealerName') { // 选择经销商
        v.props = {
          ...v.props,
          params: {
            functionCode: 'dealer_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'companyName') { // 选择公司主体
        v.props = {
          ...v.props,
          params: {
            functionCode: 'company_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'productReqVos') {
        v.props = {
          ...v.props,
          dealerCode: '',
          companyCode: '',
        };
        v.value = {
          data: [],
        };
        v.on = {
          ...v.on,
          change: this.onBudgetCode,
          clear: this.clearBudgetCode,
          deleteAssociate: this.deleteAssociate,
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.disabled(true, ['companyName', 'applyMoney']);
      const dealerName = this.getRule('dealerName');
      const companyName = this.getRule('companyName');
      const productReqVos = this.getRule('productReqVos');
      // 经销商
      dealerName.on.change = (e) => {
        if (e && e.length > 0) {
          this.dealerCode = e[0].customerCode;
          this.setValue({
            dealerName: e[0].customerName,
            dealerCode: e[0].customerCode,
          });
          this.disabled(false, ['companyName']);
          companyName.props = {
            ...companyName.props,
            params: {
              ...companyName.props.params,
              paramData: { custCode: e[0].customerCode },
            },
          };
          productReqVos.props = {
            ...productReqVos.props,
            dealerCode: e[0].customerCode,
          };
        } else {
          this.setValue({
            dealerName: null,
            dealerCode: null,
            companyName: null,
            companyCode: null,
            applyMoney: null,
          });
          this.disabled(true, ['companyName']);
          productReqVos.props = {
            ...productReqVos.props,
            dealerCode: null,
            companyCode: null,
          };
          productReqVos.value = {
            data: [],
          };
        }
      };
      // 公司主体
      companyName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            companyName: e[0].companyName,
            companyCode: e[0].companyCode,
          });
          productReqVos.props = {
            ...productReqVos.props,
            companyCode: e[0].companyCode,
          };
        } else {
          this.setValue({
            companyName: null,
            companyCode: null,
            applyMoney: null,
          });
          productReqVos.props = {
            ...productReqVos.props,
            companyCode: null,
          };
          productReqVos.value = {
            data: [],
          };
        }
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .post('/tpm/tpmtastingapply/query', {
            id: this.formConfig.row.id,
          })
          .then(async (res) => {
            if (res.success) {
              const { result } = res;
              const productCodeGiftList = result.productRespVos.map((v) => v.productCode);
              if (this.formConfig.code === 'edit') {
                const dataList = await request.post('/tpm/tpmTastingPoolDetail/findTastingPoolApplyPageList', { productCodeGiftList, companyCode: result.companyCode, dealerCode: result.dealerCode });
                if (dataList.success) {
                  result.productRespVos = result.productRespVos.map((m, index) => {
                    const obj = m;
                    obj.detailLineCode = index + 1;
                    obj.priceNew = dataList.result.data.find((s) => s.productCodeGift === obj.productCode) ? (dataList.result.data.find((s) => s.productCodeGift === obj.productCode) || {}).price : obj.price;
                    obj.moneyNew = dataList.result.data.find((s) => s.productCodeGift === obj.productCode) ? (Number((dataList.result.data.find((s) => s.productCodeGift === obj.productCode) || {}).price) || 0) * (Number(obj.num) || 0) : obj.money;
                    obj.price = this.toThousands(obj.priceNew.toFixed(2));
                    obj.money = this.toThousands(obj.moneyNew.toFixed(2));
                    return obj;
                  });
                  let applyMoney = 0;
                  result.productRespVos.forEach((i) => {
                    applyMoney = this.accAdd(applyMoney, Number((i.money.toString().replace(/,/g, '')) || 0));
                  });
                  result.applyMoney = this.toThousands(applyMoney.toFixed(2));
                }
              }
              companyName.props = {
                ...companyName.props,
                params: {
                  ...companyName.props.params,
                  paramData: { custCode: result.dealerCode },
                },
              };
              result.productReqVos = {
                data: result.productRespVos || [],
              };
              productReqVos.props = {
                ...productReqVos.props,
                dealerCode: result.dealerCode,
                companyCode: result.companyCode,
              };
              this.setValue(result);
            }
          });
      }
    },
    // 两个浮点数求和
    accAdd(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      return Math.round(num1 * m + num2 * m) / m;
    },
    // 监听商品信息
    onBudgetCode(row) {
      console.log(row, 'row');
      let applyMoney = 0;
      row.forEach((i) => {
        applyMoney = this.accAdd(applyMoney, Number((i.money.toString().replace(/,/g, '')) || 0));
      });
      applyMoney = this.toThousands(applyMoney.toFixed(2));
      this.setValue({
        applyMoney,
      });
    },
    // 数字千位符格式化
    toThousands(num) {
      const str = num.toString().replace(/,/g, '');
      const integer = str.split('.')[0]; // 获取整数位
      const decimal = str.split('.')[1]; // 获取小数
      let res = [];
      if (integer.length > 3) {
        let counter = 0;
        for (let i = integer.length - 1; i >= 0; i -= 1) {
          counter += 1;
          res.unshift(integer[i]);
          if (!(counter % 3) && i !== 0) {
            res.unshift(',');
          }
        }
        if (decimal) {
          res = `${res.join('')}.${decimal}`;
        } else {
          res = res.join('');
        }
      } else {
        res = str;
      }
      return res;
    },
    // 监听清空商品信息
    clearBudgetCode() {
      console.log('空');
      this.setValue({
        applyMoney: 0,
      });
    },
    // 删除关联信息
    async deleteAssociate(row) {
      console.log(row, 'row1');
    },
    // 提交
    async submit(type) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/tpm/tpmtastingapply/save';
        const params = formData;
        if (params.productReqVos.data && params.productReqVos.data.length === 0) {
          this.$message({
            type: 'error',
            message: '请选择商品信息!',
          });
          return false;
        }
        params.productReqVos = params.productReqVos.data || [];
        params.productReqVos = params.productReqVos.map((v) => {
          const obj = v;
          obj.money = Number((obj.money.toString().replace(/,/g, '')) || 0);
          obj.price = Number((obj.price.toString().replace(/,/g, '')) || 0);
          return obj;
        });
        params.applyMoney = Number((params.applyMoney.toString().replace(/,/g, '')) || 0);
        delete params.title1;
        delete params.title2;
        delete params.title3;
        delete params.title4;
        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmtastingapply/update';
          params.id = this.formConfig.row.id;
          if (type === 1) {
            params.saveType = 3;
          } else {
            params.saveType = 4;
          }
        } else {
          params.saveType = type;
        }
        // 跳转下一步
        if (type === 2) {
          const resData = await request.post('/tpm/tpmtastingapply/paramCheck', params);
          console.log(resData);
          if (resData.code === 200) {
            this.$emit('submit', {
              row: params,
              submitUrl: url,
            });
          }
        } else {
          const { success } = await request.post(url, params);
          if (success) {
            this.$message({
              type: 'success',
              message: '保存成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        }
      }
    },
  },
};
